import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext';
import { shadow } from '../../styles/theme'
import Modal from '../common/Modal';
import TAR_new from './TAR_new';
import Prices from "../../utils/Prices"
import DiscountTag from './DiscountTag';
import EnergyCycleComponent from './EnergyCycleComponent';
import PricesContainer from './PricesContainer';

const Wrapper = styled.div`
    margin: 60px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 1s;
`

const RowTop = styled.div`
    width: 100%;
    display: flex;
    z-index: 10;
    background-color: ${props => props.theme.default.colors.secondary};
`

const RowBottom = styled.div`
    z-index: 9;
    width: 100%;
    display: flex;
    padding: 15px 0;
    height: 75px;
    transition: 1s;
    opacity: ${props => props.expanded ? '1' : '0'};
    margin-top: ${props => props.expanded ? '0' : '-120px'};;
`

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const BottomText = styled.div`
    flex: 2;
    padding: 20px 15px;
`

const BottomAction = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ColumnSmall = styled.div`
    flex: 1;
    min-height: 50px;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px;
`

const ColumnLarge = styled.div`
    flex: 1.5;
    min-height: 50px;
    /* background-color: beige; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;

    @media(max-width: 767px) {
        flex-direction: column;
    }
`

const Label = styled.span`
    font-size: 12pt;
    display: block;
    color: ${props => props.theme.default.colors.lightMediumGrey};
    
    @media(max-width: 465px) {
        font-size: 10pt;
    }
`

const Workaroundlabel = styled(Label)`
    @media(max-width: 465px) {
        margin-left: 50px;
    }
`

const HelpLabel = styled(Label)`
    font-size: 10pt;
    color:  ${props => props.theme.default.colors.darkGrey};
`

const BigPrice = styled.span`
    font-size: 37pt;
    font-weight: 600;
    color: ${props => props.theme.default.colors.primary};

    
    @media(max-width: 768px) {
        font-size: 27pt;
    }

    @media(max-width: 465px) {
        font-size: 20pt;
    }
`

const SmallPrice = styled.span`
    font-size: 14pt;
    font-weight: 600;
    color: ${props => props.theme.default.colors.darkGrey};
    
    @media(max-width: 465px) {
        font-size: 11pt;
    }
`

const Button = styled.button`
    margin-left: 10px;
    ${shadow}
    border-radius: 50px;
    width: 20px;
    height: 20px;
    background-color: ${props => props.theme.default.colors.secondary};
    border:none;
    outline:none;
`

const TARbutton = styled.button`
    margin-left: 10px;
    ${shadow}
    border-radius: 5px;
    padding: 10px;
    background-color: ${props => props.theme.default.colors.secondary};
    border:none;
    outline:none;
`

const Title = styled.h2`
    text-transform: uppercase;
` 

export default function PricesTable({offPeakPrice, peakPrice, offPeakPVP, peakPVP, priceOffPeakDiscount, pricePeakDiscount, showContainer = true}) {
    const { appState, sendEvent } = useContext(AppContext);
    const [priceExpanded, setPriceExpanded] = useState(false)
    const [pvpExpanded, setPVPExpanded] = useState(false)
    const [openTAR, setOpenTAR] = useState(false);

    return (
        <Wrapper>
            <Title>{appState.i18n.plans.plan_prices}</Title>
            <PricesContainer showContainer={showContainer}>
                <Row>
                    <RowTop>
                        <ColumnSmall></ColumnSmall>
                        <ColumnLarge><Workaroundlabel>{appState.i18n.prices.offPeak}</Workaroundlabel></ColumnLarge>
                        <ColumnLarge><Label>{appState.i18n.prices.peak}</Label></ColumnLarge>
                    </RowTop>
                </Row>
                <Row>
                    <RowTop>
                        <ColumnSmall>
                            <Label>{appState.i18n.prices.pricePerKwH}</Label>
                            <Button onClick={() => {setPriceExpanded(!priceExpanded)}}>{priceExpanded ? '-' : '+'}</Button>
                        </ColumnSmall>
                        <ColumnLarge>
                            {priceOffPeakDiscount ? <DiscountTag message={`${appState.i18n.conditions.includes} ${priceOffPeakDiscount}`} /> : null}
                            <BigPrice>€{offPeakPrice}</BigPrice>
                        </ColumnLarge>
                        <ColumnLarge>
                            {pricePeakDiscount ? <DiscountTag message={`${appState.i18n.conditions.includes} ${pricePeakDiscount}`} /> : null}
                            <BigPrice>€{peakPrice}</BigPrice>
                        </ColumnLarge>
                    </RowTop>
                    <RowBottom expanded={priceExpanded}>
                        <BottomContainer>
                            <BottomText>
                                <HelpLabel>
                                    {appState.i18n.prices.priceWithTAR}
                                </HelpLabel>
                            </BottomText>
                            <BottomAction>
                                <TARbutton onClick={() => {setOpenTAR(true)}}>Ver TAR</TARbutton>
                            </BottomAction>
                        </BottomContainer>
                    </RowBottom>
                </Row>
                <Row>
                    <RowTop>
                        <ColumnSmall>
                            <Label>{appState.i18n.prices.pvpPerKwH}</Label>
                            <Button onClick={() => {setPVPExpanded(!pvpExpanded)}}>{pvpExpanded ? '-' : '+'}</Button>
                        </ColumnSmall>
                        <ColumnLarge>
                            <SmallPrice>€{offPeakPVP}</SmallPrice>
                        </ColumnLarge>
                        <ColumnLarge>
                            <SmallPrice>€{peakPVP}</SmallPrice>
                        </ColumnLarge>
                    </RowTop>
                    <RowBottom expanded={pvpExpanded}>
                        <BottomContainer>
                            <BottomText>
                                <HelpLabel>
                                    {appState.i18n.prices.pvpWithTaxes}
                                </HelpLabel>
                            </BottomText>
                        </BottomContainer>
                    </RowBottom>
                </Row>
                <EnergyCycleComponent />
                <Modal
                    title={appState.i18n.plans.seeTAR}
                    content={<TAR_new />}
                    actions={[{ onClick: () => { setOpenTAR(false); }, text: appState.i18n["navs"]["close"] }]}
                    isOpen={[openTAR, setOpenTAR]} />
            </PricesContainer>
        </Wrapper>
    )
}
