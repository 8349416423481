import React, { useState } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import styled from 'styled-components';
import Tooltip from '../common/Tooltip';
import MobilityTableCaptions from './MobilityTableCaptions';
import DefaultButton from '../common/Button';
import Modal from '../common/Modal';
import TAR from '../plans/TAR';
import DiscountTag from '../plans/DiscountTag';
import PricesTable from "../../utils/Prices"


const Container = styled.div`
    margin: 60px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const TableContainer_ = styled(TableContainer)`
    max-width: 800px;
    margin-left: min(5%, 100px);
    padding: 50px 0;
`

const Table_ = styled(Table)`
    border: none;
`

const TableCell_ = styled(TableCell)`
    border: none !important;
    height: 80px;
    font-size: 18px;
    font-weight: 300;
`

const TableCellTitle_ = styled(TableCell_)`
    font-size: 22px;
    font-weight: 500;
`

const Tooltip_ = styled(Tooltip)`
    margin-top: 10px;
`

const Title = styled.h2`
    text-transform: uppercase;
`

const Button = styled(DefaultButton)`
    width: 100% !important;
`

export default function MobilityPricingTable() {
    const { appState, sendEvent } = useContext(AppContext);
    const [openTAR, setOpenTAR] = useState(false);

    return (
        <Container>
            <Title>{appState.i18n.conditions.plan}</Title>
            <TableContainer_>
                <Table_ aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCellTitle_>{appState.i18n.prices.cycleSchedule}</TableCellTitle_>
                            <TableCellTitle_>{appState.i18n.prices.tarcost}<Tooltip_ text={appState.i18n.conditions.priceDisclaimer} /></TableCellTitle_>
                            <TableCellTitle_>{appState.i18n.prices.pvp}<Tooltip_ text={appState.i18n.conditions.retailPriceDisclaimer} /></TableCellTitle_>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell_>{appState.i18n.prices.offPeak}</TableCell_>
                            <TableCell_><span className="discountPrice">{`€${PricesTable.mobility.home.offPeak.priceWithDiscount}`}/kWh</span> <span className="oldPrice">{`€${PricesTable.mobility.base.offPeak.price}`}/kWh</span> <DiscountTag message={PricesTable.mobility.home.offPeak.discountOnPrice} /></TableCell_>
                            <TableCell_><span className="discountPrice">{`€${PricesTable.mobility.home.offPeak.pvpWithDiscount}`}/kWh</span> <span className="oldPrice">{`€${PricesTable.mobility.base.offPeak.pvp}`}/kWh</span></TableCell_>
                        </TableRow>
                        <TableRow>
                            <TableCell_>{appState.i18n.prices.peak}</TableCell_>
                            <TableCell_><span className="discountPrice">{`€${PricesTable.mobility.home.peak.priceWithDiscount}`}/kWh</span> <span className="oldPrice">{`€${PricesTable.mobility.base.peak.price}`}/kWh</span><DiscountTag message={PricesTable.mobility.home.peak.discountOnPrice} /></TableCell_>
                            <TableCell_><span className="discountPrice">{`€${PricesTable.mobility.home.peak.pvpWithDiscount}`}/kWh</span> <span className="oldPrice">{`€${PricesTable.mobility.base.peak.pvp}`}/kWh</span></TableCell_>
                        </TableRow>
                    </TableBody>
                </Table_>
            </TableContainer_>
            <Button primary={0} label={`+ ${appState.i18n.plans.seeTAR}`} onClick={() => {
                setOpenTAR(true);
                sendEvent("Plans", "Click TAR", "Residential");
            }} />
            <MobilityTableCaptions />
            <Modal
                title={appState.i18n.plans.seeTAR}
                content={<div>
                    <TAR />
                </div>}
                actions={[{ onClick: () => { setOpenTAR(false); }, text: appState.i18n["navs"]["close"] }]}
                isOpen={[openTAR, setOpenTAR]} />
        </Container>
    )
}
