import React from 'react'
import styled from 'styled-components'
import { shadow } from '../../styles/theme'

const Container = styled.div`
    ${shadow}
    max-width: 1000px;
    min-width: 300px;
    background-color: ${props => props.theme.default.colors.secondary};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
`

export default function PricesContainer({children, showContainer}) {
  return (
      showContainer ? 
        <Container>
            {children}
        </Container> 
        : <div>
            {children}
        </div>
  )
}
