import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext';
import VerticalList from './VerticalList';

const Container = styled.div`
    background-color: rgb(255 254 250);
    border: 2px solid rgb(248, 218, 82);
    max-width: 800px;
    border-radius: 20px;
    margin: 20px;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`

const Paragraph = styled.p`
    font-size: 10pt;
`

export default function WarningBox({ children }) {
    const { appState } = useContext(AppContext);

    return (
        <Wrapper>
            <Container>
                <VerticalList>
                    <Paragraph>{appState.i18n.plans.priceChange1}</Paragraph>
                    <Paragraph>{appState.i18n.plans.priceChange2}</Paragraph>
                    <Paragraph>{appState.i18n.plans.priceChange3}</Paragraph>
                    <Paragraph>{appState.i18n.plans.priceChange4} <a style={{ "color": "-webkit-link" }} target="_blank" href="https://www.omie.es/pt">https://www.omie.es/pt</a></Paragraph>
                </VerticalList>
            </Container>
        </Wrapper>
    )
}
