import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'

const Container = styled.div`
    margin: 60px 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 950px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Title = styled.h2`
    text-transform: uppercase;
`

export default function VerticalList({children}) {
    const { appState } = useContext(AppContext);

    return (
        <Container>
            <Wrapper>
                {children}
            </Wrapper>
        </Container>
    )
}
