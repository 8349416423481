import React, { useContext } from 'react'
import Layout from '../../../../components/layout'
import AboutPlanTower from '../../../../components/layout/AboutPlanTower'
import CardsDisplayer from '../../../../components/layout/CardsDisplayer'
import DotDivider from '../../../../components/layout/DotDivider'
import HeroContainer from '../../../../components/layout/HeroContainer'
import ResidentialConditions from '../../../../components/layout/ResidentialConditions'
import ResidentialPricingTable from '../../../../components/layout/ResidentialPricingTable'
import RegisterButton from '../../../../components/layout/RegisterButton'
import Footer from '../../../../components/layout/Footer'
import SimilarOffers from '../../../../components/layout/SimilarOffers'
import Offer from '../../../../components/layout/Offer'
import { AppContext } from '../../../../contexts/AppContext'
import SEO from '../../../../components/seo'
import Prices from "../../../../utils/Prices"
import PricesTable from '../../../../components/plans/PricesTable'
import WarningBox from '../../../../components/layout/WarningBox'



const residentialPlan_Cards = [{ key: "invoiceDiscountHome" }, { key: "freeCards" }, { key: "imediateAccess" }]

const snippets = [{
    title: "whatIsIt_Title",
    text: "whatIsIt"
},
{
    title: "howItWorks_Title",
    text: "howItWorks"
},
{
    title: "evazRole_Title",
    text: "evazRole"
},]

export default function ResidentialPage() {

    const { appState } = useContext(AppContext);

    return (
        <Layout>
            <SEO title={appState.i18n.heroContainers.residentialPageTitle} />
            <HeroContainer title={appState.i18n.heroContainers.residentialPageTitle} subtitle={appState.i18n.heroContainers.residentialPageText}  id="residential" />            
            {/* <ResidentialPricingTable /> */}
            <PricesTable offPeakPrice={Prices.mobility.home.offPeak.priceWithDiscount} peakPrice={Prices.mobility.home.peak.priceWithDiscount} offPeakPVP={Prices.mobility.home.offPeak.pvpWithDiscount} peakPVP={Prices.mobility.home.peak.pvpWithDiscount} priceOffPeakDiscount={Prices.mobility.home.offPeak.discountOnPrice} pricePeakDiscount={Prices.mobility.home.peak.discountOnPrice}/>
            <RegisterButton />
            <WarningBox />
            {/* <DotDivider />
            <CardsDisplayer cards={residentialPlan_Cards} />
            <DotDivider /> */}
            <AboutPlanTower page={"residential"} snippets={snippets}/>
            {/* <DotDivider /> */}
            <DotDivider />
            <ResidentialConditions />
            <RegisterButton />
            <DotDivider />
            <SimilarOffers title={appState.i18n.similarOffers.title}>
                <Offer link={"/solutions/private/powerpack"} title={appState.i18n.similarOffers.powerpacksTitle} text={appState.i18n.similarOffers.powerpacksText}/>
                <Offer link={"/solutions/plans/mobility"} title={appState.i18n.similarOffers.mobilityPlanTitle} text={appState.i18n.similarOffers.mobilityPlanText}/>
            </SimilarOffers>
            <Footer />
        </Layout>
    )
}
