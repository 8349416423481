import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    color: white;
    display: inline-block;
    width: fit-content;
    padding: 4px 7px;
    background-color:var(--tertiary-color);
    border-radius: 20px;
    line-height: 12pt;
`

export default function DiscountTag({message, className}) {
    return (
        <Container className={className}>
            {message}
        </Container>
    )
}
