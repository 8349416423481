import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

export default function PeakHoursTable() {

    const { appState } = useContext(AppContext);
    return (
        <div>
            <div className="table_container_width">
                {/* <label>{appState.i18n.prices.peakHoursTable}</label> */}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow style={{backgroundColor: "var(--secondary-bg)"}}>
                                <TableCell>{appState.i18n.prices.cycle.toUpperCase()}</TableCell>
                                <TableCell colSpan="2">{appState.i18n.prices.daily.toUpperCase()}</TableCell>
                                <TableCell colSpan="2">{appState.i18n.prices.weekly.toUpperCase()}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{backgroundColor: "var(--secondary-bg)"}}>
                                <TableCell rowSpan="6">{appState.i18n.prices.offPeak}</TableCell>
                                <TableCell>{appState.i18n.prices.weekDays}</TableCell>
                                <TableCell>{appState.i18n.prices.winterAndSummer}</TableCell>
                                <TableCell>{appState.i18n.prices.winter}</TableCell>
                                <TableCell>{appState.i18n.prices.summer}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{backgroundColor: "var(--secondary-bg)"}}>{appState.i18n.prices.mondayToFriday}</TableCell>
                                <TableCell rowSpan="5">22:00 - 08:00</TableCell>
                                <TableCell colSpan="2">00:00 - 07:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{backgroundColor: "var(--secondary-bg)"}} rowSpan="3">{appState.i18n.prices.saturday}</TableCell>
                                <TableCell>00:00 - 09:30</TableCell>
                                <TableCell>00:00 - 09:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>13:00 - 18:30</TableCell>
                                <TableCell>14:00 - 20:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>22:00 - 00:00</TableCell>
                                <TableCell>22:00 - 00:00</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{backgroundColor: "var(--secondary-bg)"}}>{appState.i18n.prices.sunday}</TableCell>
                                <TableCell colSpan="2">{appState.i18n.prices.allDay}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <h4>{appState.i18n["registration"]["info_emptyhours_title"]}</h4>
                <p>{appState.i18n["registration"]["info_emptyhours_text"]}</p>

                <h4>{appState.i18n["registration"]["info_nonemptyhours_title"]}</h4>
                <p>{appState.i18n["registration"]["info_nonemptyhours_text"]}</p>
                <div className="plans-dragToSeeMore" style={{fontSize: "9pt", color: "var(--secondary-type-color)", marginTop: "5px"}}>{appState.i18n.plans.mobileDragHorizontal}</div>
            </div>
        </div>
    )
}

