import React, { useContext } from 'react'
import { Table, TableContainer, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core'
import { AppContext } from '../../contexts/AppContext';
import PricesTable from "../../utils/Prices"


export default function TAR() {
    const { appState, sendEvent } = useContext(AppContext);
    return (
        <div className="table_container_width">
            {/* <label>{appState.i18n.prices.priceTable}</label> */}
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{backgroundColor: "var(--secondary-bg)"}}>
                            <TableCell>{appState.i18n.prices.cycle.toUpperCase()}</TableCell>
                            <TableCell colSpan={2} align="center">TAR*</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={2} style={{backgroundColor: "var(--secondary-bg)"}}>{appState.i18n.prices.offPeak}</TableCell>
                            <TableCell>{appState.i18n.plans.lowTension}</TableCell>
                            <TableCell>{PricesTable.TAR.offPeak.lowTension}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{appState.i18n.plans.mediumTension}</TableCell>
                            <TableCell>{PricesTable.TAR.offPeak.mediumTension}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={2} style={{backgroundColor: "var(--secondary-bg)"}}>{appState.i18n.prices.peak}</TableCell>
                            <TableCell>{appState.i18n.plans.lowTension}</TableCell>
                            <TableCell>{PricesTable.TAR.peak.lowTension}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{appState.i18n.plans.mediumTension}</TableCell>
                            <TableCell>{PricesTable.TAR.peak.mediumTension}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="plans-dragToSeeMore" style={{fontSize: "9pt", color: "var(--secondary-type-color)", marginTop: "5px"}}>{appState.i18n.plans.mobileDragHorizontal}</div>
            <label>{appState.i18n.prices.networkTaxNote2} <a onClick={ () => sendEvent("Plans", "TAR Link", "TAR") } style={{textDecoration: "underline"}} target="_blank" rel="noopener noreferrer" href="https://www.erse.pt/atividade/regulacao/tarifas-e-precos-eletricidade/">{appState.i18n.all.here}</a></label>
        </div>
    )
}
