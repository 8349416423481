import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext';
import { TAR_new } from '../../components/plans/TAR_new'
import { shadow } from '../../styles/theme';
import PeakHoursTable from './PeakHoursTable';
import Modal from '../common/Modal'

const TARbutton = styled.button`
    margin-left: 10px;
    ${shadow}
    border-radius: 5px;
    padding: 10px;
    background-color: ${props => props.theme.default.colors.secondary};
    border:none;
    outline:none;
    cursor: pointer;
    color: ${props => props.theme.default.colors.mediumGrey};
`

const Wrapper = styled.div`
    margin: 40px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export default function EnergyCycleComponent() {
    const [openTAR, setOpenTAR] = useState(false);
    const { appState } = useContext(AppContext);

    return (
        <Wrapper>
            <TARbutton onClick={() => {setOpenTAR(true)}}>{appState.i18n.prices.seeCycle}</TARbutton>
            <Modal
                    title={appState.i18n.prices.cycleSchedule}
                    content={<PeakHoursTable/>}
                    actions={[{ onClick: () => { setOpenTAR(false); }, text: appState.i18n["navs"]["close"] }]}
                    isOpen={[openTAR, setOpenTAR]} />
        </Wrapper>
    )
}
